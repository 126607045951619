import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import configureStore from "../components/GlobalSearch/store";
import "../styles/topNav/autocomplete_header.scss";
import AutoCompleteSearchHeader from "../components/GlobalSearch/components/common/autocompleteHeader";
import "../i18n";

export const store = configureStore();

const GlobalSearchHeader = ({ keyId }) => {
  useEffect(() => {
    const globalSearch = document.querySelector(`#${keyId}`);
    const topNavMob = document.querySelector(".top-nav-mob");

    if (globalSearch && topNavMob) {
      topNavMob.classList.add("bg-white");
    }

    const openSearch = () => {
      const mainNav = document.querySelector("#top-nav-wrapper");
      if (mainNav) {
        mainNav.classList.add("search-open");
        removeContainerClickListener();
      }
    };

    const closeSearch = () => {
      addContainerClickListener();
    };

    const openButton = document.querySelector(".open-global-search-btn");
    const closeButton = document.querySelector(".close-global-search-btn");
    const autocompleteContainer = document.querySelector(
      ".autocomplete-input-container"
    );

    const handleContainerClick = () => {
      if (
        !document
          .querySelector("#top-nav-wrapper")
          .classList.contains("search-open")
      ) {
        openButton.click();
      }
    };

    const addContainerClickListener = () => {
      if (autocompleteContainer) {
        autocompleteContainer.addEventListener("click", handleContainerClick);
      }
    };

    const removeContainerClickListener = () => {
      if (autocompleteContainer) {
        autocompleteContainer.removeEventListener(
          "click",
          handleContainerClick
        );
      }
    };

    if (openButton) {
      openButton.addEventListener("click", openSearch);
    }
    if (closeButton) {
      closeButton.addEventListener("click", closeSearch);
    }

    addContainerClickListener();

    return () => {
      if (openButton) {
        openButton.removeEventListener("click", openSearch);
      }
      if (closeButton) {
        closeButton.removeEventListener("click", closeSearch);
      }
      removeContainerClickListener();
    };
  }, [keyId]);

  return (
    <Provider store={store}>
      <AutoCompleteSearchHeader />
    </Provider>
  );
};

GlobalSearchHeader.propTypes = {
  keyId: PropTypes.string.isRequired,
};

const renderGlobalSearchHeader = () => {
  const body = document.body;
  if (
    body.classList.contains("to-be-referenced-page") ||
    body.classList.contains("repertoire-page")
  ) {
    return;
  }
  const isDesktop = window.innerWidth >= 1200;
  const domNodeId = isDesktop
    ? "global-search-header"
    : "global-search-header-mob";
  const domNode = document.querySelector(`#${domNodeId}`);

  if (domNode) {
    const keyId = isDesktop ? "desktop-search" : "mobile-search";
    // eslint-disable-next-line
    ReactDOM.render(<GlobalSearchHeader keyId={keyId} />, domNode);
  }
};

document.addEventListener("DOMContentLoaded", renderGlobalSearchHeader);

let prevInnerHeight = window.innerHeight;

window.addEventListener("resize", () => {
  const currentInnerHeight = window.innerHeight;

  // Only trigger re-render if it's not a keyboard-related resize
  if (Math.abs(prevInnerHeight - currentInnerHeight) > 200) {
    const desktopNode = document.querySelector("#global-search-header");
    const mobileNode = document.querySelector("#global-search-header-mob");

    if (desktopNode) {
      ReactDOM.unmountComponentAtNode(desktopNode);
    }
    if (mobileNode) {
      ReactDOM.unmountComponentAtNode(mobileNode);
    }
    renderGlobalSearchHeader();
  }

  prevInnerHeight = currentInnerHeight; // Update for next resize
});
